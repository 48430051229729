import { validateLimitVolume } from 'utils/familyLimitVolume';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Service, serviceProxy } from 'utils';
import { fetchFilterSearch } from 'reduxs/searchFilterSlice';
import { setError } from 'reduxs/errorSlice';

// 另外處理 drug 的 3x 圖片
const replaceImage = (s) => s.replace('-2x.', '-3x.');

// TODO: 現階段先拆開，未來再考慮整合
// [drug]商品頁
export const fetchDrugProduct = createAsyncThunk(
  'product/fetchDrugProduct',
  async (id, { dispatch, rejectWithValue }) => {
    const resData = await Service.drugProduct(id);
    const { code, data, errMsg } = resData;

    // 其他無法預期的錯誤
    if (code < 0) {
      return rejectWithValue(errMsg);
    }

    // 需要排除 error 的欄位
    const {
      description,
      tagStrippedDescription,
      supplierProductDescription,
      supplierDescription,
      ...rest
    } = data;

    // 先 log 最一開始的 response，若需要加強再補 variation + quantities
    dispatch(setError(rest));

    const variation = await serviceProxy({
      url: data.otherDataUrlV3,
      except: true,
    });

    if (!variation || !variation?.allItemData?.length) {
      return rejectWithValue('variationError');
    }

    const quantities = await serviceProxy({
      url: data.availableQuantityUrl,
      except: true,
      noCache: true, // 避免可銷量 cache
    });

    if (!quantities || !quantities?.length) {
      return rejectWithValue('quantitiesError');
    }

    data.imageUrl = replaceImage(data.imageUrl);
    variation.images = variation.images.map((image) => replaceImage(image));

    return {
      variation,
      quantities,
      product: data,
    };
  }
);

// [rakuma]商品頁
export const fetchRakumaProduct = createAsyncThunk(
  'product/fetchRakumaProduct',
  async (id, { rejectWithValue }) => {
    const resData = await Service.rakumaProduct(id);
    const { code, data, errMsg } = resData;

    // 其他無法預期的錯誤
    if (code < 0) {
      return rejectWithValue({
        type: 'product',
        message: errMsg,
      });
    }

    return { code, data };
  }
);

// [rakuma]廣告區塊
export const fetchProductAdvertise = createAsyncThunk(
  'product/fetchProductAdvertise',
  async (_, { rejectWithValue }) => {
    let resDataPosition = {};
    let resDataAds = [];
    const resData = await Service.home();

    // TODO: 是否改為 error boundary 接 error?
    // 找不到 product.positions
    if (!resData.product.positions) {
      return rejectWithValue({
        type: 'product.positions',
        message: '缺少 product.positions 欄位',
      });
    }

    // 取商品區塊
    resDataPosition = await serviceProxy({
      url: resData.product.positions,
      except: true,
    });

    // 找不到 productAdvertisements.rakuma
    if (!resDataPosition.productAdvertisements.rakuma) {
      return rejectWithValue({
        type: 'productAdvertisements.rakuma',
        message: '缺少 productAdvertisements.rakuma 欄位',
      });
    }

    // 取商品區塊
    resDataAds = await serviceProxy({
      url: resDataPosition.productAdvertisements.rakuma[0],
      except: true,
    });

    // 回傳為空
    if (!resDataAds.length) {
      return rejectWithValue({
        type: 'productAdvertisements.rakuma[0]',
        message: '資料為空',
      });
    }

    // 取商品描述廣告
    const resDataDescription = resDataPosition.productDescriptionAdvertisements
      ?.rakuma?.[0]
      ? await serviceProxy({
          url: resDataPosition.productDescriptionAdvertisements.rakuma[0],
          except: true,
        })
      : null;

    // 目前都固定一筆，所以直接取第一筆
    const response = {
      advertise: resDataAds[0],
      descriptionAdvertise: resDataDescription[0],
    };

    return response;
  }
);

// [rakuma]搜尋區塊(賣家)
export const fetchSearchSeller = createAsyncThunk(
  'product/fetchSearchSeller',
  async (sellerId, { dispatch, rejectWithValue }) => {
    const resData = await dispatch(fetchFilterSearch({ sellerId })).unwrap();

    // 其他 API 錯誤
    if (resData.hasError) {
      return rejectWithValue({
        type: 'sellers',
        message: '取賣家資料錯誤',
      });
    }

    return resData.list;
  }
);

// [rakuma]搜尋區塊(品牌)
export const fetchSearchBrand = createAsyncThunk(
  'product/fetchSearchBrand',
  async (brandId, { dispatch, rejectWithValue }) => {
    const resData = await dispatch(fetchFilterSearch({ brandId })).unwrap();

    // 其他 API 錯誤
    if (resData.hasError) {
      return rejectWithValue({
        type: 'brands',
        message: '取品牌取資料錯誤',
      });
    }

    return resData.list;
  }
);

// [rakuma]文字翻譯
export const fetchTextTranslate = createAsyncThunk(
  'product/fetchTextTranslate',
  async (reqData, { rejectWithValue }) => {
    const resData = await Service.rakumaTextTranslate(reqData);
    const { code, data, errMsg } = resData;

    // 其他無法預期的錯誤
    if (code < 0) {
      return rejectWithValue({
        type: 'translate',
        message: errMsg,
      });
    }

    return data.translatedWord;
  }
);

/**
 * 規格元件(variation)
 *   - 商品頁中可能同時存在列表商品彈窗需拆分 state 管理
 */
// [drug]variation
export const fetchDrugSlideProduct = createAsyncThunk(
  'product/fetchDrugSlideProduct',
  async (id, { dispatch, rejectWithValue }) => {
    const resData = await Service.drugProduct(id);
    const { code, data, errMsg } = resData;

    // 下架或其他無法預期的錯誤
    if (code < 0) {
      return rejectWithValue(errMsg);
    }

    // 需要排除 error 的欄位
    const {
      description,
      tagStrippedDescription,
      supplierProductDescription,
      supplierDescription,
      ...rest
    } = data;

    // 先 log 最一開始的 response，若需要加強再補 variation + quantities
    dispatch(setError(rest));

    const variation = await serviceProxy({
      url: data.otherDataUrlV3,
      except: true,
    });

    if (!variation || !variation?.allItemData?.length) {
      return rejectWithValue('variationError');
    }

    const quantities = await serviceProxy({
      url: data.availableQuantityUrl,
      except: true,
      noCache: true, // 避免可銷量 cache
    });

    if (!quantities || !quantities?.length) {
      return rejectWithValue('quantitiesError');
    }

    data.imageUrl = replaceImage(data.imageUrl);
    variation.images = variation.images.map((image) => replaceImage(image));

    return {
      variation,
      quantities,
      product: data,
    };
  }
);

// [rakuma]variation
export const fetchRakumaSlideProduct = createAsyncThunk(
  'product/fetchRakumaSlideProduct',
  async (id, { rejectWithValue }) => {
    const resData = await Service.rakumaProduct(id);
    const { code, data, errMsg } = resData;

    // 其他無法預期的錯誤
    if (code < 0) {
      return rejectWithValue({
        type: 'variation',
        message: errMsg,
      });
    }

    return data;
  }
);

const initialState = {
  isLoading: {
    data: true,
    advertise: true,
    descriptionAdvertise: true,
    translate: false,
    searchSeller: false,
    searchBrand: false,
  },
  loading: null,
  error: null,
  data: {},
  product: {},
  variation: {},
  quantities: {},
  itemIdsArray: [],
  itemId: null,
  advertise: null,
  descriptionAdvertise: null,
  searchProduct: {},
  translate: '',
  drugSlide: {
    product: {},
    variation: {},
    quantities: {},
    itemIdsArray: [],
    loading: null,
    error: null,
  },
  rakumaSlide: {
    data: {},
    variation: {},
    quantities: {},
    itemIdsArray: [],
    loading: null,
    error: null,
  },
  isErrors: {
    data: null,
    advertise: null,
    descriptionAdvertise: null,
    translate: null,
    searchSeller: null,
    searchBrand: null,
  },
};

const productSlice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    resetProduct: () => initialState,
    resetDrugSlideProduct: (state) => {
      state.drugSlide = { ...initialState.drugSlide };
    },
    resetRakumaSlideProduct: (state) => {
      state.rakumaSlide = { ...initialState.rakumaSlide };
    },
    setCurrentId: (state, { payload }) => {
      state.itemId = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDrugProduct.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchDrugProduct.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.product = payload.product;
        state.variation = payload.variation;
        state.quantities = payload.quantities.reduce((obj, item) => {
          const [id, value] = Object.entries(item)[0];
          obj[id] = value;
          return obj;
        }, {});

        state.itemIdsArray = payload?.variation.allItemData.reduce(
          (acc, item) => {
            acc.push(item.optionIds);
            return acc;
          },
          []
        );

        /**
         * 檢查材積: 一個規格符合就顯示全家取貨
         *  - 最長邊不超過 50、另兩邊不超過 45
         *  - 長 + 寬 + 高 < 120
         */
        state.product.isVerifiedVolume = payload.variation.itemData.some(
          (obj) => validateLimitVolume(obj.length, obj.width, obj.height)
        );
      })
      .addCase(fetchDrugProduct.rejected, (state, { payload }) => {
        state.loading = false;
        state.product = initialState.product;
        state.variation = initialState.variation;
        state.quantities = initialState.quantities;
        state.error = payload;
      })
      .addCase(fetchRakumaProduct.fulfilled, (state, { payload }) => {
        state.isLoading.data = false;
        state.data = payload.data;
      })
      .addCase(fetchRakumaProduct.rejected, (state, { payload }) => {
        state.isLoading.data = false;
        state.isErrors.data = payload;
      })
      .addCase(fetchProductAdvertise.fulfilled, (state, { payload }) => {
        state.isLoading.advertise = false;
        state.advertise = payload.advertise;
        state.descriptionAdvertise = payload.descriptionAdvertise;
      })
      .addCase(fetchProductAdvertise.rejected, (state, { payload }) => {
        state.isLoading.advertise = false;
        state.isLoading.descriptionAdvertise = false;
        state.isErrors.advertise = payload.advertise;
        state.isErrors.descriptionAdvertise = payload.descriptionAdvertise;
        state.advertise = null;
      })
      .addCase(fetchTextTranslate.pending, (state) => {
        state.isLoading.translate = true;
      })
      .addCase(fetchTextTranslate.fulfilled, (state, { payload }) => {
        state.isLoading.translate = false;
        state.translate = payload;
      })
      .addCase(fetchTextTranslate.rejected, (state, { payload }) => {
        state.isLoading.translate = false;
        state.isErrors.translate = payload;
      })
      .addCase(fetchSearchSeller.pending, (state) => {
        state.isLoading.searchSeller = true;
      })
      .addCase(fetchSearchSeller.fulfilled, (state, { payload }) => {
        state.isLoading.searchSeller = false;
        state.searchProduct.sellers = payload;
      })
      .addCase(fetchSearchSeller.rejected, (state, { payload }) => {
        state.isLoading.searchSeller = false;
        state.isErrors.searchSeller = payload;
        state.searchProduct.sellers = [];
      })
      .addCase(fetchSearchBrand.pending, (state) => {
        state.isLoading.searchBrand = true;
      })
      .addCase(fetchSearchBrand.fulfilled, (state, { payload }) => {
        state.isLoading.searchBrand = false;
        state.searchProduct.brands = payload;
      })
      .addCase(fetchSearchBrand.rejected, (state, { payload }) => {
        state.isLoading.searchBrand = false;
        state.isErrors.searchBrand = payload;
        state.searchProduct.brands = [];
      })
      .addCase(fetchDrugSlideProduct.pending, (state) => {
        state.drugSlide = { ...initialState.drugSlide };
        state.drugSlide.loading = true;
      })
      .addCase(fetchDrugSlideProduct.fulfilled, (state, { payload }) => {
        state.drugSlide.loading = false;
        state.drugSlide.product = payload.product;
        state.drugSlide.variation = payload.variation;
        state.drugSlide.quantities = payload.quantities.reduce((obj, item) => {
          const [id, value] = Object.entries(item)[0];
          obj[id] = value;
          return obj;
        }, {});
        state.drugSlide.itemIdsArray = payload?.variation.allItemData.reduce(
          (acc, item) => {
            acc.push(item.optionIds);
            return acc;
          },
          []
        );
      })
      .addCase(fetchDrugSlideProduct.rejected, (state, { payload }) => {
        state.drugSlide.loading = false;
        state.drugSlide.error = payload;
      })
      .addCase(fetchRakumaSlideProduct.pending, (state) => {
        state.rakumaSlide = { ...initialState.rakumaSlide };
        state.rakumaSlide.loading = true;
      })
      .addCase(fetchRakumaSlideProduct.fulfilled, (state, { payload }) => {
        state.rakumaSlide.loading = false;
        state.rakumaSlide.data = payload;
      })
      .addCase(fetchRakumaSlideProduct.rejected, (state, { payload }) => {
        state.rakumaSlide.loading = false;
        state.rakumaSlide.error = payload;
      });
  },
});

export const {
  setCurrentId,
  resetProduct,
  resetDrugSlideProduct,
  resetRakumaSlideProduct,
} = productSlice.actions;

export default productSlice.reducer;
