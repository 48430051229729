import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { createApi } from '@reduxjs/toolkit/query/react';

import { serviceProxy, Service, formatCurrencyReverseRule } from 'utils';

// 搜尋結果 Banner
export const fetchSearchResultBanner = createAsyncThunk(
  'search/fetchSearchResultBanner',
  async () => {
    const res = await Service.searchResultBanner();
    const { positions } = res.search;

    const contents = await serviceProxy({
      url: positions,
      except: true,
      cache: true,
    });

    const searchResultBanner = await serviceProxy({
      url: contents?.searchResultAdvertisements.rakuma[0],
      except: true,
      cache: true,
    });

    return searchResultBanner;
  }
);

// 處理 query parameters 字串
const handleFilterParams = (params) => {
  let paramsList = [];
  const excludeKeys = ['brandName', 'sellerName', 'platform', 'isSort', 'from'];

  Object.entries(params).map(([key, value]) => {
    if (value && !excludeKeys.includes(key)) {
      if (key === 'minPrice' || key === 'maxPrice') {
        const formatPrice = formatCurrencyReverseRule(key, value);
        paramsList.push(`${key}=${formatPrice}`);
      } else if (key === 'businessType') {
        // 店家種類若為二手店需以 business_type[]=2&business_type[] = 6 形式帶參數打 api
        paramsList.push(
          +value === 2 ? `${key}[]=${value}&${key}[]=6` : `${key}=${value}`
        );
      } else {
        paramsList.push(`${key}=${value}`);
      }
    }
  });

  return paramsList.join('&');
};

// 搜尋結果: 商品頁
export const fetchFilterSearch = createAsyncThunk(
  'search/fetchFilterSearch',
  async (params, { dispatch }) => {
    const paramsPayload = handleFilterParams(params);

    dispatch(resetSearchResult());
    const res = await Service.searchResults(paramsPayload);

    // 其他非預期錯誤、沒有 data
    const apiResError = res.code < 0 || !res.data;

    const formatRes = apiResError ? initialState.searchResult : res.data;

    return formatRes;
  }
);

const initialState = {
  advancedSearchDrawerOpen: false,
  dropDownFilterType: null,
  filterText: {
    category: { tempText: '', showText: '' },
    brand: { tempText: '', showText: '' },
    status: { tempText: '', showText: '' },
    price: { tempText: '', showText: '' },
  },
  secondMenu: [], // 分類第二層
  thirdMenu: [], // 分類第三層
  searchResult: { hit: {}, list: [], brandData: {}, sellerData: {} },
  searchResultBanner: [],
  searchLoading: false,
  scrollSearchRakumaMemory: {
    params: '',
    position: { start: null, end: null },
    visit: 0,
    memory: 0,
  },
};

const searchFilterSlice = createSlice({
  name: 'searchFilter',
  initialState,
  reducers: {
    setAdvancedSearchDrawerOpen: (state) => {
      state.advancedSearchDrawerOpen = !state.advancedSearchDrawerOpen;
    },
    setDropdownFilterType: (state, { payload }) => {
      state.dropDownFilterType = payload;
    },
    setSearchResult: (state, { payload }) => {
      state.searchResult = payload;
    },
    resetSearchResult: (state) => {
      state.searchResult = initialState.searchResult;
    },
    resetFilterText: (state, { payload }) => {
      if (!payload) {
        state.filterText.category.tempText = '';
        state.filterText.brand.tempText = '';
        state.filterText.status.tempText = '';
        state.filterText.price.tempText = '';
      } else {
        state.filterText[payload].tempText = '';
      }
    },
    setFilterTextTemp: (state, { payload }) => {
      state.filterText[payload.type].tempText = payload.text;
    },
    setFilterTextShow: (state, { payload }) => {
      if (!payload) {
        state.filterText.category.showText = state.filterText.category.tempText;
        state.filterText.brand.showText = state.filterText.brand.tempText;
        state.filterText.status.showText = state.filterText.status.tempText;
        state.filterText.price.showText = state.filterText.price.tempText;
      } else {
        state.filterText[payload].showText = state.filterText[payload].tempText;
      }
    },
    setSecondMenu: (state, { payload }) => {
      state.secondMenu = payload;
    },
    setThirdMenu: (state, { payload }) => {
      state.thirdMenu = payload;
    },
    resetSearchRakumaScroll: (state) => {
      state.scrollSearchRakumaMemory = initialState.scrollSearchRakumaMemory;
    },
    setSearchRakumaScroll: (state, { payload }) => {
      state.scrollSearchRakumaMemory = payload;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchSearchResultBanner.fulfilled, (state, { payload }) => {
        state.searchResultBanner = payload;
      })
      .addCase(fetchFilterSearch.pending, (state) => {
        state.searchLoading = true;
      })
      .addCase(fetchFilterSearch.fulfilled, (state, { payload }) => {
        state.searchLoading = false;
        state.searchResult = payload;
      })
      .addCase(fetchFilterSearch.rejected, (state) => {
        state.searchLoading = false;
        state.searchResult = initialState.searchResult;
      });
  },
});

export const {
  setAdvancedSearchDrawerOpen,
  setDropdownFilterType,
  setSearchResult,
  resetSearchResult,
  resetFilterText,
  setFilterTextTemp,
  setFilterTextShow,
  setSecondMenu,
  setThirdMenu,
  setSearchRakumaScroll,
  resetSearchRakumaScroll,
} = searchFilterSlice.actions;
export default searchFilterSlice.reducer;

// rakume: search 品牌 下拉頁專用
const defaultParams = {
  products: [],
  hit: {},
  list: [],
  brandData: {},
  sellerData: {},
  totalCount: 0,
  totalPage: 0,
};

export const searchFilterApi = createApi({
  reducerPath: 'searchFilterApi',
  keepUnusedDataFor: 60 * 3, // 資料保留3分鐘
  endpoints: (builder) => ({
    searchRakuma: builder.query({
      queryFn: async (reqData) => {
        const { page, ...rest } = reqData;

        let paramsPayload = handleFilterParams(rest);
        let pageQuery = `&page=${page ?? 1}`;
        const resData = await Service.searchResults(paramsPayload + pageQuery);

        const { code, data } = resData;

        // 其他無法預期的錯誤
        if (code < 0 || resData?.hasError) {
          return {
            data: defaultParams,
            error: '',
          };
        }

        return {
          data: {
            ...data,
            totalCount: data.hit.totalCount,
            totalPage: data.hit.totalPage,
            page: page,
            params: paramsPayload,
          },
        };
      },
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },
      merge: (currentCache, newItems) => {
        if (newItems.params !== currentCache.params) {
          return newItems;
        }
        return {
          ...newItems,
          list: [...currentCache.list, ...newItems.list],
        };
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
    }),
  }),
});

export const useSearchRakumaState =
  searchFilterApi.endpoints.searchRakuma.useQueryState;
export const { useLazySearchRakumaQuery } = searchFilterApi;
export const resetSearchRakuma = () => searchFilterApi.util.resetApiState();
