import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { serviceProxy } from 'utils';

export const initialState = {
  loading: null,
  error: null,
  init: true,
  response: {
    specialSeriesAdvertisements: {
      data: [],
      url: '',
    },
    specialSeriesArea: {
      Anime: {},
      Bag: {},
      Accessory: {},
      Wallet: {},
      Apparel: {},
      Watch: {},
    },
    specialSeriesKeywordTags: {
      Anime: {},
      Bag: {},
      Accessory: {},
      Wallet: {},
      Apparel: {},
      Watch: {},
    },
  },
};

// 特輯
export const fetchRakumaSeries = createAsyncThunk(
  'rakumaSeries/fetchRakumaSeries',
  async ({ category }, { getState }) => {
    const state = getState();
    const channelKey = state.common.adChannelKey;
    const specialSeriesUrl = state.home.specialSeriesUrl;
    const data = {
      specialSeriesArea: {},
      specialSeriesKeywordTags: {},
    };
    const urls = await serviceProxy({
      url: specialSeriesUrl,
      except: true,
      cache: true,
    });

    const { specialSeriesAdvertisements } = urls;

    const categoryUrls = Object.keys(urls).reduce((acc, key) => {
      if (key.includes(category)) {
        acc[key] = urls[key];
      }
      return acc;
    }, {});

    for (let key in { ...categoryUrls, specialSeriesAdvertisements }) {
      if (urls[key]?.[channelKey]) {
        const section = await serviceProxy({
          url: urls[key][channelKey][0],
          except: true,
          cache: true,
        });

        const isCategoryKey = key.includes(category);

        if (isCategoryKey) {
          // 挑出 key 中 fixedCategory 後的字
          const tempData = { data: section, url: urls[key]?.[channelKey]?.url };

          if (key.includes('KeywordTag')) {
            data['specialSeriesKeywordTags'][category] = tempData;
          } else {
            const series = key.split('specialSeries')[1];
            const seriesData = { [series]: tempData };

            data['specialSeriesArea'][category] = {
              ...(data['specialSeriesArea'][category] || {}),
              ...seriesData,
            };
          }
        } else {
          data[key] = {
            data: section,
            url: urls[key]?.[channelKey].url,
          };
        }
      }
    }
    return {
      data: { ...data },
      category,
    };
  }
);

const rakumaSeriesSlice = createSlice({
  name: 'rakumaSeries',
  initialState,
  reducers: {
    resetRakumaSeries: (state) => {
      state.loading = initialState.loading;
      state.response = initialState.response;
      state.error = initialState.error;
      state.init = initialState.init;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchRakumaSeries.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchRakumaSeries.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.response.specialSeriesAdvertisements =
          payload.data.specialSeriesAdvertisements;
        state.response.specialSeriesArea[payload.category] =
          payload.data.specialSeriesArea[payload.category];
        state.response.specialSeriesKeywordTags[payload.category] =
          payload.data.specialSeriesKeywordTags[payload.category];
        state.init = false;
      })
      .addCase(fetchRakumaSeries.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      });
  },
});

export const { resetRakumaSeries } = rakumaSeriesSlice.actions;

export default rakumaSeriesSlice.reducer;
