import { constants } from 'utils';

const { orderDeliveryInfo, defaultImage } = constants;

/**
 * 複製到剪貼簿
 * @param {*} func: Function to set tip
 * @param {*} value: Copy value
 * @param {*} tip: About copy action Tip content
 */
export const handleCopy = async (func, value, tip) => {
  if (navigator.clipboard && window.isSecureContext) {
    await navigator.clipboard.writeText(value);
    func(tip);
  } else {
    // FIX: http測試環境用, 上正式後可移除
    func(tip);
  }
};

/**
 * // TODO: 之後要再調整並統一業務邏輯(國內配送 type)
 * 全家包裹查詢
 * @param {*} name: The name to match delivery info
 * @returns {object}
 */
export const handleDeliveryInfo = (name) => {
  if (name.includes('新竹')) {
    return orderDeliveryInfo[0];
  }

  if (name.includes('黑貓')) {
    return orderDeliveryInfo[1];
  }

  if (name.includes('全家')) {
    return orderDeliveryInfo[2];
  }

  return {
    name,
    url: '',
  };
};

/**
 *
 * @param {*} eventName - GA event
 * @param {*} items: ecommerce content
 */
export const GAEcommerceEvent = (eventName, items) => {
  window.dataLayer.push({ ecommerce: null });
  window.dataLayer.push({
    event: `${eventName}`,
    ecommerce: items,
  });
};

/**
 *
 * @param {*} e - image DOM event
 */
export const onImageError = (e) => {
  e.target.onerror = null;
  e.target.src = defaultImage;
};

/**
 *
 * @param {object} data : From home product - when data have id & key, use promotion route
 * @returns {string} url
 */
export const usePromotionUrl = (data) => {
  const blockKeys = [
    'salesRanking',
    'latestProducts',
    'highlyRatedJapanAmazonProducts',
  ];

  let url = data?.url ?? '/';

  if (data?.id && data?.key && blockKeys.includes(data.key)) {
    url = `/promotion/${data.key}?id=${data.id}`;
  }

  return url;
};

export const uuidGenerator = () => {
  let d = Date.now();
  if (
    typeof performance !== 'undefined' &&
    typeof performance.now === 'function'
  ) {
    d += performance.now(); //use high-precision timer if available
  }
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    let r = (d + Math.random() * 16) % 16 | 0;
    d = Math.floor(d / 16);
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
  });
};

/**
 *
 * @param {*} componentImport
 * @returns any: props need lazy load
 */
export const lazyRetry = (componentImport) => {
  return new Promise((resolve, reject) => {
    const hasRefreshed = JSON.parse(
      window.sessionStorage.getItem('retry-lazy-refreshed') || 'false'
    );
    componentImport()
      .then((component) => {
        window.sessionStorage.setItem('retry-lazy-refreshed', 'false');
        resolve(component);
      })
      .catch((error) => {
        if (!hasRefreshed) {
          window.sessionStorage.setItem('retry-lazy-refreshed', 'true');
          return window.location.reload();
        }
        reject(error);
      });
  });
};

/**
 * 使用者裝置確認
 * @returns string || null
 */
export const getUserDeviceSystem = () => {
  // 取得userAgent，並將字串轉換為小寫
  let ua = navigator.userAgent.toLowerCase();

  // 使用正規表示法來過濾字串：match符合回傳true，反之回傳false
  let ios = ua.match(/(ios|iphone|ipad|ipod)/gi);
  let android = ua.match(/android/gi);

  if (ios) {
    return 'ios';
  } else if (android) {
    return 'android';
  } else {
    return null; // 其他裝置，這邊不考慮
  }
};

/**
 * 確認是否使用 iphone
 * @returns boolean
 */
export const checkIsIphone = () => {
  // 取得userAgent，並將字串轉換為小寫
  let ua = navigator.userAgent.toLowerCase();
  return ua.indexOf('iphone') > -1;
};
