import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { createApi } from '@reduxjs/toolkit/query/react';
import { serviceProxy, Service } from 'utils';

// 搜尋標籤
export const fetchSearchTags = createAsyncThunk(
  'search/fetchSearchTags',
  async (_, { rejectWithValue }) => {
    try {
      const res = await Service.searchTags();
      const { positions } = res.search;

      const contents = await serviceProxy({
        url: positions,
        except: true,
        cache: true,
      });
      const keywordTags = await serviceProxy({
        url: contents?.keywordTags.all[0],
        except: true,
        cache: true,
      });

      return keywordTags;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const initialState = {
  loading: null,
  error: null,
  tags: [],
  init: true,
  scrollSearchMemory: {
    keyword: '',
    position: { start: null, end: null },
    visit: 0,
    memory: 0,
  },
  scrollBrandMemory: {
    brandId: '',
    position: { start: null, end: null },
    visit: 0,
    memory: 0,
  },
};

const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    resetSearchTags: (state) => {
      state.loading = initialState.loading;
      state.error = initialState.error;
      state.tags = initialState.tags;
      state.init = initialState.init;
    },
    resetSearchScroll: (state) => {
      state.scrollSearchMemory = initialState.scrollSearchMemory;
    },
    resetBrandScroll: (state) => {
      state.scrollBrandMemory = initialState.scrollBrandMemory;
    },
    setSearchScroll: (state, { payload }) => {
      state.scrollSearchMemory = payload;
    },
    setBrandScroll: (state, { payload }) => {
      state.scrollBrandMemory = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSearchTags.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchSearchTags.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.tags = payload;
        state.init = false;
      })
      .addCase(fetchSearchTags.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      });
  },
});

export const {
  resetSearchTags,
  resetSearchScroll,
  resetBrandScroll,
  setSearchScroll,
  setBrandScroll,
} = searchSlice.actions;
export default searchSlice.reducer;

const defaultParams = {
  products: [],
  totalCount: 0,
};

// 搜尋結果列表
export const searchApi = createApi({
  reducerPath: 'searchApi',
  keepUnusedDataFor: 60 * 3, // 3分鐘
  endpoints: (builder) => ({
    searchByKeyword: builder.query({
      queryFn: async ({ keyword, channelIds, start, end }) => {
        const categoryIds = channelIds.join(',');
        const resData = await Service.searchRangeQuery(keyword, {
          categoryIds,
          start,
          end,
          sort: 'id_desc',
          platformKey: 'family-mart',
        });
        const { code, data, errMsg } = resData;

        // 其他無法預期的錯誤
        if (code < 0) {
          return {
            data: defaultParams,
            error: errMsg || '沒有相關搜尋',
          };
        }

        const { totalCount, products } = data;

        return {
          data: {
            products,
            totalCount,
            keyword,
            start,
            end,
          },
        };
      },
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },
      merge: (currentCache, newItems) => {
        if (currentCache) {
          // 切換頁籤不做任何暫存
          if (newItems.keyword !== currentCache.keyword) {
            return newItems;
          }
          return {
            ...newItems,
            products: [...currentCache.products, ...newItems.products],
          };
        }
        return newItems;
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
    }),
  }),
});

export const useSearchRangeState =
  searchApi.endpoints.searchByKeyword.useQueryState;
export const { useSearchByKeywordQuery, useLazySearchByKeywordQuery } =
  searchApi;
export const resetSearch = () => searchApi.util.resetApiState();

// 品牌列表
export const brandApi = createApi({
  reducerPath: 'brandApi',
  keepUnusedDataFor: 60 * 3, // 3分鐘
  endpoints: (builder) => ({
    searchByBrand: builder.query({
      queryFn: async ({ brandId, channelIds, start, end }) => {
        const resData = await Service.searchBrandRangeQuery(brandId, {
          categoryIds: channelIds.join(','),
          sort: 'id_desc',
          start,
          end,
          platformKey: 'family-mart',
        });
        const { code, data, errMsg } = resData;

        // 其他無法預期的錯誤
        if (code < 0) {
          return {
            data: defaultParams,
            error: errMsg || '沒有相關品牌',
          };
        }

        const { totalCount, products } = data;

        return {
          data: {
            products,
            totalCount,
            brandId,
            start,
            end,
          },
        };
      },
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },
      merge: (currentCache, newItems) => {
        if (currentCache) {
          // 切換頁籤不做任何暫存
          if (newItems.brandId !== currentCache.brandId) {
            return newItems;
          }
          return {
            ...newItems,
            products: [...currentCache.products, ...newItems.products],
          };
        }

        return newItems;
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
    }),
  }),
});

export const useBrandRangeState =
  brandApi.endpoints.searchByBrand.useQueryState;
export const { useSearchByBrandQuery, useLazySearchByBrandQuery } = brandApi;
export const resetSearchBrand = () => brandApi.util.resetApiState();
