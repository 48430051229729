import {
  createAsyncThunk,
  createSlice,
  createSelector,
} from '@reduxjs/toolkit';
import {
  Service,
  constants,
  suspense,
  decodeStorageData,
  calculate,
  dayjs,
  getCookie,
  shipmentTimeByMultiple,
  shipmentMethodByMultiple,
} from 'utils';
import { storageDataReceiverAndStore, exchangeApi } from 'reduxs/commonSlice';

const { token } = constants;
const platform = 'familyMart';
let tempReqData = {};

// TODO: 加密部分會影響主站效能，之後要換成 js 處理加密這段
// 整理送參數結構: 購物車資訊加密
const formatDataCartItemEncode = async (params) => {
  const productInfo = params.items.reduce(
    (
      acc,
      {
        productName,
        maxOrderQuantity,
        productId: productID,
        productImageUrl: img,
        productWeight,
        productItemId: specificationNo,
        productUrl,
        salePrice,
        quantity: num,
        brandId: brand_id,
        sellerId,
      }
    ) => {
      acc.push({
        price: salePrice,
        name: productName,
        url: productUrl,
        num,
        brand_id,
        sellerId,
        supplier_platform: platform,
        seller_name: params.cartName,
        name_lst: params.cartName,
        supplierKey: params.cartKey,
        seller: platform,
        salePrice,
        productName,
        productUrl,
        purchaseQuantity: num,
        img,
        productWeight,
        productID,
        maxOrderQuantity,
        specificationNo,
        platform,
        site: 'fmmart.bibian.co.jp',
        nation: 'japan',
      });

      return acc;
    },
    []
  );

  /**
   * 送參數
   *  - status: 固定 encodeprodinfo
   *  - platform: 頻道
   *  - ez1
   *  - productInfo: 購物車品項
   */
  const reqData = {
    status: 'encodeprodinfo',
    platform,
    ez1: token,
    productInfo,
  };

  return reqData;
};

// 整理送參數結構: 活動資格
const formatDataEventQualify = async (params) => {
  const now = dayjs().format('YYYY-MM-DD HH:mm:ss');
  const prodInfo = params.items.reduce(
    (
      acc,
      {
        productName: prodname,
        productId,
        productWeight,
        productItemId,
        productUrl: url,
        salePrice,
        quantity,
        brandId: brand_id,
        sellerId,
      }
    ) => {
      acc.push({
        prodname,
        num: `${quantity}`,
        price: `${salePrice}`, // 取特價
        url,
        brand_id, // 給折價券用
        sellerId, // 給折價券用
        supplierKey: params.cartKey,
        productWeight: `${productWeight}`,
        productID: `${productId}`,
        specification_no: `${productItemId}`,
      });

      return acc;
    },
    []
  );

  /**
   * 參數
   *  - idmbr_bid: 會員編號
   *  - exchange_rate_bid: 匯率
   *  - won_quantity_bid: 固定 1
   *  - end_time_bid: 現在時間(YYYY-MM-DD HH:mm:ss)，例如 2024-05-07 00:00:14
   *  - order_time_bid: 現在時間(YYYY-MM-DD HH:mm:ss)，例如 2024-05-07 00:00:14
   *  - bidorbuy_lst: 購物車品項特價總計(台幣)，需加上國際運費 (weight_fee)
   *  - buy_info_bid_arr: 購物車品項加密
   *  - supplier: 固定 familyMart
   *  - supplierKey: 供應商，給主站對應好的 key，由購物車帶過來
   *  - prodInfo: 購物車品項
   *  - weight_fee: 國際空運費，會根據免運門檻而不同 (有 eventFee 就抓 eventFee，沒有就抓 fee)，不仰賴行銷工具需給原價(或活動價)
   *  - transit_tw_dlr: 國內運送方式
   *  - taiwanDeliveryFee: 國內段運費 (抓 fee)，因仰賴行銷工具需給原價
   */
  const reqData = {
    idmbr_bid: params.uid,
    exchange_rate_bid: params.exchange,
    won_quantity_bid: '1',
    end_time_bid: now,
    order_time_bid: now,
    bidorbuy_lst: calculate(
      params.totalData.priceSale + params.tidyFeeData.international.special
    ),
    buy_info_bid_arr: params.encodeData,
    supplier: platform,
    supplierKey: params.items.flatMap(
      ({ bibianMainSupplierKey }) => bibianMainSupplierKey
    ),
    prodInfo,
    weight_fee: params.tidyFeeData.international.special,
    transit_tw_dlr: params.tidyFeeData.bbnMethodKey,
    taiwanDeliveryFee: params.tidyFeeData.domestic.original,
  };

  return reqData;
};

// TODO: 因結帳頁串主站參數有相依性，待之後換成新的行銷工具 API，應把折價券相關移到 couponSlice 統一管理
// 整理回傳欄位名稱對應
export const mappingParams = (data) =>
  data.map((obj) => {
    const {
      id,
      ticket_number: ticketNo, // 折價券代號
      web_name: name,
      title,
      type_group: groupName, // 群組名稱(單品、頻道、館別、其他)
      type_tags: channel, // 頻道
      ticket_qty: availableTimes, // 使用次數，此欄位為倒扣法，直接顯示即可
      coupon_start_at: startAt,
      expired_at: endAt,
      currency,
      limits, // 館別分類(對應商城供應商)
      qualified, // 是否符合資格
      discount_fee, // 折抵相關資訊
    } = obj;

    // 主站寫死 "BBC-XXX"，因此需先濾掉 "BBC-" 字眼再轉全小寫
    const limit = limits?.bbntw_order_supplier
      ? limits?.bbntw_order_supplier[0]?.replace('BBC-', '').toLowerCase()
      : '';

    return {
      id,
      ticketNo,
      name: name.replace(';折價券', ''), // 與主站共用結構，value 會寫死 ";折價券"，因此需先濾掉
      title,
      status: 'unuse',
      groupName,
      channel,
      availableTimes,
      startAt,
      endAt,
      currency,
      limit,
      qualified: !!qualified,
      discount: discount_fee.NT,
    };
  });

// 向主站取活動資格判定
export const loadFromStorage = createAsyncThunk(
  'pay/loadFromStorage',
  async () => {
    const tempData = decodeStorageData('selectedItems');
    const result = {
      ...tempData,
      leadTime: shipmentTimeByMultiple(tempData.items),
      shipment: shipmentMethodByMultiple(tempData.items),
      cartSubtotal: tempData.items.reduce(
        (acc, { listPrice, salePrice, quantity }) => {
          acc = {
            listPrice: calculate(acc.listPrice + listPrice * quantity),
            salePrice: calculate(acc.salePrice + salePrice * quantity),
          };

          return acc;
        },
        {
          listPrice: 0,
          salePrice: 0,
        }
      ),
      enableFastArrival: tempData.items.every(
        (item) => item.supplierKey === 'bibian'
      ),
      hasSupplierTerms: tempData.items.some(
        (item) => item.supplierKey === 'benesse.deposit'
      ),
    };

    return result;
  }
);

// 向主站取活動資格判定
export const fetchEventQualify = createAsyncThunk(
  'pay/fetchEventQualify',
  async (_, { getState, rejectWithValue, dispatch }) => {
    const state = getState();
    const uid = state.userInfo.uid;

    // 從購物車帶來的選取資料
    const { platform, cartKey, cartName, items, totalData } =
      state.pay.pageData;
    const tempParams = {
      platform,
      cartKey,
      cartName,
      items,
      totalData,
    };

    // 取得 rtk query 中的值(匯率)
    const { data: exchange } = await dispatch(
      exchangeApi.endpoints.exchange.initiate(null, { forceRefetch: true })
    );

    // 購物車品項加密
    const reqParamEncode = await formatDataCartItemEncode(tempParams);
    const resDataEncode = await Service.cartItemEncode(reqParamEncode);

    // 加密 API 錯誤
    if (resDataEncode.hasError) {
      return rejectWithValue({
        type: 'cartItemEncode',
        message: '購物車品項加密錯誤',
      });
    }

    // 取得活動資格
    const reqData = await formatDataEventQualify({
      ...tempParams,
      tidyFeeData: state.pay.tidyFeeData,
      uid,
      exchange,
      encodeData: resDataEncode.productInfo,
    });
    const resData = await Service.eventQualify(reqData);

    // 給其他 fn 用而暫存
    tempReqData = {
      platform: tempParams.platform,
      params: reqData,
    };

    /**
     * 取活動 API 錯誤
     * Ex. API 直接掛掉或是傳結構有錯，但為了畫面正常且計算不能錯，在畫面上跳 modal 提示
     */
    if (resData.hasError) {
      return rejectWithValue({
        type: 'eventQualify',
        message: '活動資格錯誤',
      });
    }

    return resData ?? [];
  }
);

// 向主站取可使用折價券
export const fetchAvailableCoupon = createAsyncThunk(
  'pay/fetchAvailableCoupon',
  async (_, { rejectWithValue }) => {
    const resData = await Service.availableCouponList(tempReqData.params);

    /**
     * 沒有折價券情境會回傳不同資料結構，因此這邊要給預設值空陣列確保程式不會出錯
     * 可使用折價券需過濾當前頻道與全站通用(bibian)
     */
    let resList = resData.length
      ? resData.filter(
          ({ type_tags }) =>
            type_tags === 'bibian' || type_tags === tempReqData.platform
        )
      : [];

    // 其他無法預期的錯誤 (API掛掉等等)
    if (resData.hasError) {
      return rejectWithValue({
        type: 'availableCouponList',
        message: '可使用折價券錯誤',
      });
    }

    return resList;
  }
);

// 取得折扣碼對應全家店舖資訊
export const fetchFamilyCodeWithStore = createAsyncThunk(
  'pay/fetchFamilyCodeWithStore',
  async (reqData, { rejectWithValue }) => {
    const resData = await Service.familyCodeWithStore(reqData);
    const { code, errMsg: message, data } = resData;

    // 其他無法預期的錯誤
    if (code < 0) {
      return rejectWithValue({
        type: 'familyCodeWithStore',
        message,
      });
    }

    return data;
  }
);

// 向主站取可使用全家店舖代碼
export const fetchAvailableFamilyCode = createAsyncThunk(
  'pay/fetchAvailableFamilyCode',
  async (reqData, { rejectWithValue }) => {
    const resData = await Service.availableFamilyCode({
      ...tempReqData.params,
      campaign_code: reqData,
      source:
        process.env.REACT_APP_ENV === 'dev' ? process.env.REACT_APP_ENV : 'www',
    });

    // 其他無法預期的錯誤 (API掛掉等等)
    if (resData.hasError) {
      return rejectWithValue({
        type: 'availableFamilyCode',
        message: '全家店舖代碼錯誤',
      });
    }

    // 客製化錯誤情境，因後端回傳結構不一致，需統一結構給畫面
    if (!resData.length) {
      return rejectWithValue(resData.type_code);
    }

    const [obj] = resData;

    return {
      code: obj.code,
      name: obj.name,
      discount: obj.discount_fee.NT,
    };
  }
);

// 付款機制
const fetchOrder = async (reqData) => {
  let resData = {};
  let retry = 0;

  do {
    resData = await Service.orderCreate(reqData);

    if (resData.code === -5001) {
      await suspense();
      retry += 1;

      // 平均 0.3 秒重打，超過 5 秒就停掉(約 15 次)
      if (retry >= 15) break;
    }
  } while (resData.code === -5001);

  return resData;
};

// 結帳
export const orderCreate = createAsyncThunk(
  'pay/orderCreate',
  async (reqData, { dispatch, rejectWithValue }) => {
    // 取得 rtk query 中的值(匯率)
    const { data: exchangeRate } = await dispatch(
      exchangeApi.endpoints.exchange.initiate(null, { forceRefetch: true })
    );

    let resData = {};
    let queryStatus = {};
    let retry = 0;

    // 不知為何需要前端傳 GA 給主站，目前先寫死這坨垃圾
    const domain = process.env.REACT_APP_DOMAIN;
    const gaId = getCookie('_ga_XD8XSCGH5W', { domain });
    const gaValue = getCookie('_ga', { domain });

    const tempData = {
      ...reqData,
      languageCode: 2,
      currency: 'TWD',
      exchangeRate,
      cookies: {
        ga_client: gaValue,
        ga_client_value: gaValue,
        session_id: gaId === 'deleted' ? null : gaId,
      },
    };

    resData = await fetchOrder(tempData);

    // 其他無法預期的錯誤
    if (resData.code < 0) {
      return rejectWithValue({
        type: 'order',
        code: resData.code,
        message: resData.errMsg,
        errorReqData: tempData,
        errorReqMethod: `[${resData.code}] ${resData.method} ${resData.apiUrl}`,
      });
    }

    do {
      queryStatus = await Service.orderQuery(resData.data);

      if (queryStatus.code === -5001) {
        await suspense(500);
        retry += 1;

        // 平均 0.5 秒重打，超過 30 次就停掉
        if (retry >= 30) break;
      }
    } while (queryStatus.code === -5001);

    // 其他無法預期的錯誤
    if (queryStatus.code < 0) {
      return rejectWithValue({
        type: 'order-query',
        code: queryStatus.code,
        message: queryStatus.errMsg,
        errorReqData: tempData,
        errorReqMethod: `[${queryStatus.code}] ${queryStatus.method} ${queryStatus.apiUrl}`,
      });
    }

    return queryStatus;
  }
);

const initialState = {
  isLoading: {
    pageData: false,
    events: false,
    coupons: false,
    order: false,
  },
  pageData: null,
  tidyFeeData: null,
  events: {
    list: [],
    total: 0,
  },
  coupons: [],
  selectedCoupon: {},
  familyCode: {},
  isErrors: {
    manageData: null,
    pageData: null,
    events: null,
    coupons: null,
    familyCode: null,
  },
};

const paySlice = createSlice({
  name: 'pay',
  initialState,
  reducers: {
    setTidyFeeData: (state, { payload }) => {
      const { international, domestic } = payload;

      // 國際運費
      const tempDataInternational = international.current;
      const resultInternational = tempDataInternational.isPeriod
        ? tempDataInternational.eventFee
        : tempDataInternational.fee;

      // 國內運費
      const tempDataDomestic = domestic.current.fees;
      const resultDomestic = tempDataDomestic.isPeriod
        ? tempDataDomestic.eventFee
        : tempDataDomestic.fee;

      state.tidyFeeData = {
        bbnMethodKey: domestic.current.bbnMainKey,
        international: {
          original: international.initial,
          special: resultInternational,
        },
        domestic: {
          original: tempDataDomestic.fee,
          special: resultDomestic,
        },
      };
    },
    setSelectedCoupon: (state, { payload }) => {
      state.selectedCoupon = payload;
    },
    resetData: () => initialState,
    resetFamilyCode: (state) => {
      state.familyCode = initialState.familyCode;
    },
    resetSelectedCoupon: (state) => {
      state.selectedCoupon = initialState.selectedCoupon;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadFromStorage.pending, (state) => {
        state.isLoading.pageData = true;
      })
      .addCase(loadFromStorage.fulfilled, (state, { payload }) => {
        state.isLoading.pageData = false;
        state.pageData = payload;
      })
      .addCase(fetchEventQualify.pending, (state) => {
        state.isLoading.events = true;
      })
      .addCase(fetchEventQualify.fulfilled, (state, { payload }) => {
        let eventTotal = 0;
        state.isLoading.events = false;
        state.events = {
          list: payload.reduce((acc, { name, discount_fee }) => {
            acc.push({
              name,
              fee: +discount_fee.NT,
            });

            eventTotal += +discount_fee.NT;
            return acc;
          }, []),
          total: eventTotal,
        };
      })
      .addCase(fetchEventQualify.rejected, (state, { payload }) => {
        state.isLoading.events = false;
        state.isErrors.events = payload;
      })
      .addCase(fetchAvailableCoupon.pending, (state) => {
        state.isLoading.coupons = true;
      })
      .addCase(fetchAvailableCoupon.fulfilled, (state, { payload }) => {
        state.isLoading.coupons = false;

        // 需按照有資格放最前，不符合資格放最後呈現
        state.coupons = mappingParams(payload).sort(
          (a, b) => b.qualified - a.qualified
        );
      })
      .addCase(fetchAvailableCoupon.rejected, (state, { payload }) => {
        state.isLoading.coupons = false;
        state.isErrors.coupons = payload;
      })
      .addCase(fetchFamilyCodeWithStore.fulfilled, (state, { payload }) => {
        state.familyCode.store = payload;
      })
      .addCase(fetchFamilyCodeWithStore.rejected, (state, { payload }) => {
        state.isErrors.familyCode = payload;
      })
      .addCase(fetchAvailableFamilyCode.fulfilled, (state, { payload }) => {
        state.familyCode.discount = payload;
      })
      .addCase(fetchAvailableFamilyCode.rejected, (state, { payload }) => {
        state.isErrors.familyCode = payload;
        state.familyCode = initialState.familyCode;
      })
      .addCase(orderCreate.pending, (state) => {
        state.isLoading.order = true;
      })
      .addCase(orderCreate.fulfilled, (state) => {
        state.isLoading.order = false;
      })
      .addCase(orderCreate.rejected, (state, { payload }) => {
        const { type, code, message, errorReqData, errorReqMethod } = payload;
        state.isLoading.order = false;

        // TODO: 需整併到 isErrors
        state.errorType = type;
        state.code = code;
        state.message = message;
        state.errorReqData = errorReqData;
        state.errorReqMethod = errorReqMethod;
      });
  },
});

export const {
  setTidyFeeData,
  setSelectedCoupon,
  resetData,
  resetFamilyCode,
  resetSelectedCoupon,
} = paySlice.actions;
export default paySlice.reducer;

//
export const selectedReceiverAndStoreSelector = createSelector(
  storageDataReceiverAndStore,
  (state) => state.stores.list,
  (state) => state.receiver.list,
  (selected, store, receiver) => {
    const tempReceiver = receiver.find(({ preference }) => preference) ?? {};
    const tempStore = store.find(({ preference }) => preference) ?? {};
    const tempData = {
      receiver: Object.keys(selected.receiver).length
        ? selected.receiver
        : tempReceiver,
      store: Object.keys(selected.store).length ? selected.store : tempStore,
    };

    return tempData;
  }
);

// 計算加總
export const summarySelector = createSelector(
  (state) => state.pay,
  (data) => {
    if (!data.tidyFeeData) return 0;

    const { pageData, tidyFeeData, events, selectedCoupon, familyCode } = data;

    // 加總部分: 勾選金額小計+國際運費+國內運費-(運費活動折抵+折價券折抵+全家店舖代碼)
    const result = calculate(
      pageData.totalData.priceSale +
        tidyFeeData.international.special +
        tidyFeeData.domestic.original -
        (events.total +
          (selectedCoupon.discount ?? 0) +
          (familyCode?.discount?.discount ?? 0))
    );

    return result;
  }
);
