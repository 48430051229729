import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { serviceProxy, Service } from 'utils';

const initialState = {
  brandMenu: [],
  categoryMenu: [],
  isCategoriesLoading: true,
  rightMenuList: [],
  activeCategoryId: '',
  recommendationsMenu: [],
};

export const fetchBrandMenu = createAsyncThunk(
  'menu/fetchBrandMenu',
  async (page) => {
    return await Service.brandMenu(page);
  }
);

export const fetchCategoryMenu = createAsyncThunk(
  'menu/fetchCategoryMenu',
  async () => {
    return await Service.categoryMenu();
  }
);

export const fetchRecommendationsMenu = createAsyncThunk(
  'menu/fetchRecommendationsMenu',
  async () => {
    const res = await Service.searchTags();
    const { positions } = res.search;

    const contents = await serviceProxy({
      url: positions,
      except: true,
      cache: true,
    });
    const recommendations = await serviceProxy({
      url: contents?.searchRecommendations['rakuma'][0],
      except: true,
      cache: true,
    });

    return recommendations;
  }
);

const rakumaMenuSlice = createSlice({
  name: 'rakumaMenu',
  initialState,
  reducers: {
    setActiveCategoryId: (state, { payload }) => {
      state.activeCategoryId = payload;
      // 以 user 點擊的分類 id 設定分類頁右半邊顯示的分類列表，品牌以 brand 辨識
      if (payload === 'brand') {
        state.rightMenuList = state.brandMenu;
      } else {
        state.rightMenuList = state.categoryMenu.filter(
          (val) => val.categoryId === payload
        );
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchBrandMenu.fulfilled, (state, action) => {
        state.activeCategoryId = 'brand';
        state.brandMenu = action.payload?.data.list || [];
      })
      .addCase(fetchBrandMenu.rejected, (state) => {
        state.brandMenu = initialState.brandMenu;
      })
      .addCase(fetchCategoryMenu.pending, (state) => {
        state.isCategoriesLoading = true;
      })
      .addCase(fetchCategoryMenu.fulfilled, (state, { payload }) => {
        state.categoryMenu = payload?.data.list || [];
        state.isCategoriesLoading = false;
      })
      .addCase(fetchCategoryMenu.rejected, (state) => {
        state.categoryMenu = initialState.categoryMenu;
        state.isCategoriesLoading = false;
      })
      .addCase(fetchRecommendationsMenu.pending, (state) => {
        state.isCategoriesLoading = true;
      })
      .addCase(fetchRecommendationsMenu.fulfilled, (state, { payload }) => {
        state.recommendationsMenu = payload;
        state.isCategoriesLoading = false;
      })
      .addCase(fetchRecommendationsMenu.rejected, (state) => {
        state.recommendationsMenu = initialState.recommendationsMenu;
        state.isCategoriesLoading = false;
      });
  },
});
export const { setActiveCategoryId } = rakumaMenuSlice.actions;

export default rakumaMenuSlice.reducer;
