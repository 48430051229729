import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { createApi } from '@reduxjs/toolkit/query/react';
import { Service, suspense, checkExpiration, dayjs, constants } from 'utils';

const { token } = constants;

const getSessionCache = (platform) => {
  const listKey = `restockIdList_${platform}`;
  const sessionObj = sessionStorage.getItem(listKey);
  if (!sessionObj) {
    return null;
  }
  return JSON.parse(sessionObj);
};

const updateSessionCache = (platform, data) => {
  const listKey = `restockIdList_${platform}`;
  sessionStorage.setItem(listKey, JSON.stringify(data));
};

const deleteSessionCache = (platform) => {
  const listKey = `restockIdList_${platform}`;
  sessionStorage.removeItem(listKey);
};

export const restockCreate = createAsyncThunk(
  'restock/restockCreate',
  async (reqData, { getState, rejectWithValue }) => {
    let resData = {};
    let queryStatus = {};
    let retry = 0;

    // list to update cache
    const state = getState();
    const listData = state.restock.idList;

    // 第一步驟: 將reqData 透過API送回
    do {
      resData = await Service.restockCreate({
        ...reqData,
        languageCode: 2,
        platformKey: 'family-mart',
      });
      if (resData.code === -5001) {
        await suspense(500);
        retry += 1;
        // 平均 0.5 秒重打，超過 5 秒就停掉(約 10 次)
        if (retry >= 10) break;
      }
    } while (resData.code === -5001);

    // 新增失敗，或其他無法預期的錯誤
    if (resData.code < 0) {
      return rejectWithValue(`Error: ${resData.code}] ${resData.method}`);
    }

    // 第二步驟: 資料送出成功， 取回傳結果的queryStatus，確定是否已寫入後台
    do {
      queryStatus = await Service.restockQuery(resData.data);
      if (queryStatus.code === -5001) {
        await suspense(500);
        retry += 1;

        // 平均 0.5 秒重打，超過 30 次就停掉
        if (retry >= 30) break;
      }
    } while (queryStatus.code === -5001);

    // queryStatus錯誤
    if (queryStatus.code < 0) {
      return rejectWithValue(
        `Error: ${queryStatus.code}] ${queryStatus.method}`
      );
    }
    // 將新增結果暫存於session的快取
    updateSessionCache(reqData.channelKey, {
      ...listData,
      list: [...listData.list, `${reqData.productId}-${reqData.productItemId}`],
    });
    return queryStatus;
  }
);

const handelCache = (channelKey) => {
  let checkResult = false;
  const cachedValue = getSessionCache(channelKey);
  /**
   * 補貨通知的快取
   *  - 回傳自帶timestamp(unix) = startAt，效期為20分鐘
   *  - 前端處理 startAt + 20分 = endAt
   *  - 存入 sessionStorage 快取
   */
  if (cachedValue) {
    checkResult = checkExpiration(cachedValue);
  }

  if (cachedValue && checkResult) {
    return { ...cachedValue, channelKey };
  }
  // 過期後刪除
  deleteSessionCache(channelKey);
  return null;
};

export const restockIdList = createAsyncThunk(
  'restock/restockIdList',
  async (reqData, { rejectWithValue }) => {
    if (!token) {
      return initialState.idList;
    }
    const { channelKey } = reqData;

    let cachedList = null;
    cachedList = handelCache(channelKey);

    if (cachedList) {
      return cachedList;
    }

    let resData = {};
    let retry = 0;

    do {
      resData = await Service.restockIdList({
        channelKey,
        platformKey: 'family-mart',
      });

      if (resData.code === -5001) {
        await suspense(500);
        retry += 1;

        // 平均 0.5 秒重打，超過 5 秒就停掉(約 10 次)
        if (retry >= 10) break;
      }
    } while (resData.code === -5001);

    if (resData.hasError) {
      return { error: '取得補貨通知清單ID出錯' };
    }

    const { code, data, errMsg } = resData;

    // 其他無法預期的錯誤
    if (code < 0) {
      return rejectWithValue(`Error: ${code}] ${errMsg}`);
    }

    cachedList = {
      list: data.list,
      startAt: dayjs.unix(data.timestamp).format('YYYY-MM-DD HH:mm:ss'),
      endAt: dayjs
        .unix(data.timestamp)
        .add(20, 'minute')
        .format('YYYY-MM-DD HH:mm:ss'),
      channelKey,
    };

    updateSessionCache(channelKey, cachedList);

    return cachedList;
  }
);

const initialState = {
  loading: null,
  idList: {
    list: [],
    startAt: '',
    endAt: '',
  },
  error: null,
};

const restockSlice = createSlice({
  name: 'restock',
  initialState,
  reducers: {
    resetSearchTags: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      // New restock
      .addCase(restockCreate.pending, (state) => {
        state.loading = true;
      })
      .addCase(restockCreate.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(restockCreate.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      // restock ids
      .addCase(restockIdList.pending, (state) => {
        state.loading = true;
      })
      .addCase(restockIdList.fulfilled, (state, { payload }) => {
        state.loading = false;
        if (payload.channelKey === 'rakuma') {
          state.rakumaIdList = { ...initialState.rakumaIdList, ...payload };
        } else {
          state.idList = { ...initialState.idList, ...payload };
        }
      })
      .addCase(restockIdList.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      });
  },
});

export default restockSlice.reducer;

const defaultParams = {
  products: [],
  totalCount: 0,
};

// 補貨通知清單
export const restockApi = createApi({
  reducerPath: 'restockApi',
  endpoints: (builder) => ({
    getRestockList: builder.query({
      queryFn: async ({ channelKey, start, end }) => {
        let resData = {};
        let retry = 0;
        do {
          resData = await Service.restockList({
            platformKey: 'family-mart',
            channelKey,
            start,
            end,
          });

          if (resData.code === -5001) {
            await suspense(500);
            retry += 1;

            // 平均 0.5 秒重打，超過 5 秒就停掉(約 10 次)
            if (retry >= 10) break;
          }
        } while (resData.code === -5001);
        const { code, data, errMsg } = resData;

        // 其他無法預期的錯誤
        if (code < 0) {
          return {
            data: defaultParams,
            error: errMsg || '您目前沒有訂閱補貨通知的商品',
          };
        }

        const { total, list } = data;
        return {
          data: {
            products: list,
            totalCount: total,
          },
        };
      },
      serializeQueryArgs: ({ endpointName, queryArgs }) => {
        return endpointName + queryArgs;
      },
      merge: (currentCache, newItems) => {
        if (currentCache) {
          return {
            ...newItems,
            products: [...currentCache.products, ...newItems.products],
          };
        }
        return newItems;
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
    }),
    /**
     * builder.mutation 與同一組 builder.query 搭配
     * 這裡的deleteItem行為，專門處理無限下拉的補貨通知商品清單
     * 執行時會即時更新畫面上的list data，去確保下拉載入新一批資料時
     * 已刪除的資料不會錯位，導致畫面出現重複項目
     */
    deleteItem: builder.mutation({
      queryFn: async (reqData) => {
        let resData = {};
        let queryStatus = {};
        let retry = 0;

        // 第一步驟: 將需要移除的 補貨通知ID 透過API送回
        do {
          resData = await Service.restockDelete({
            ...reqData,
            platformKey: 'family-mart',
          });

          if (resData.code === -5001) {
            await suspense(500);
            retry += 1;

            // 平均 0.5 秒重打，超過 5 秒就停掉(約 10 次)
            if (retry >= 10) break;
          }
        } while (resData.code === -5001);

        // 刪除失敗，或其他無法預期的錯誤
        if (resData.code < 0) {
          return {
            data: defaultParams,
            error: `Error: ${resData.code}] ${resData.method}` || '移除失敗',
          };
        }

        // 第二步驟: 取回傳結果的queryStatus，確定是否已寫入後台(與新增補貨通知共用同一支API)
        do {
          queryStatus = await Service.restockQuery(resData.data);
          if (queryStatus.code === -5001) {
            await suspense(500);
            retry += 1;

            // 平均 0.5 秒重打，超過 30 次就停掉
            if (retry >= 30) break;
          }
        } while (queryStatus.code === -5001);

        // queryStatus錯誤
        if (queryStatus.code < 0) {
          return {
            data: defaultParams,
            error:
              `Error: ${queryStatus.code}] ${queryStatus.method}` ||
              '後台更新失敗',
          };
        }

        return resData;
      },
      /**
       * onQueryStarted: 用來更新client畫面的快取 (Optimistic Updates)
       * 當deleteItem 執行，不會觸發 getRestockList 的 fetch行為
       */
      onQueryStarted: async (data, { dispatch, queryFulfilled }) => {
        const deletePatch = dispatch(
          restockApi.util.updateQueryData('getRestockList', data, (draft) => {
            const index = draft.products.findIndex(
              (product) => +product?.notificationId === +data.ids
            );
            /**
             * 只移除 getRestockList 的列表快取資料中對應id的物件
             *  - 從快取中移除刪除的ID，再存回session
             *  - API 不需要用到的 productItemId & productId，用來對照移除的物件
             */
            if (index >= 0) {
              draft.products.splice(index, 1);
              const cachedValue = getSessionCache(data.channelKey);
              const newList = cachedValue?.list.filter(
                (item) => item !== `${data.productId}-${data.productItemId}`
              );
              updateSessionCache(data.channelKey, {
                ...cachedValue,
                list: newList,
              });
            }
          })
        );
        // queryFulfilled.catch: fetch 失敗時執行undo，維持原本的快取資料
        queryFulfilled.catch(deletePatch.undo);
      },
    }),
  }),
});

export const { useGetRestockListQuery, useDeleteItemMutation } = restockApi;
export const resetRestock = () => restockApi.util.resetApiState();
