import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { setAdChannelKey, setSearchKeyword } from 'reduxs/commonSlice';
import {
  SearchContainer,
  StyledWrapper,
  StyledInput,
  StyledButtonWrap,
  StyledButton,
  StyledSelectorWrapper,
  StyledTextWrapper,
} from './styled';

const SearchBar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const { searchKeyword, adChannelKey, fakeInputVisible } = useSelector(
    (state) => state.common
  );
  const [keyword, setKeyword] = useState('');
  const [isSearchOn, setIsSearchOn] = useState(false);
  const [isSearchOptionOn, setIsSearchOptionOn] = useState(true);
  // TODO: 後續搜尋結果頁合併 可移除 路徑判斷
  const platform = location.pathname.includes('/search/')
    ? 'drug'
    : searchParams.get('platform') ?? 'rakuma';

  useEffect(() => {
    if (searchKeyword) {
      setKeyword(searchKeyword);
    }
  }, [searchKeyword]);

  useEffect(() => {
    if (location?.pathname === '/search') {
      setIsSearchOn(false);
      setIsSearchOptionOn(true);
      dispatch(setAdChannelKey(platform));
      if (searchKeyword === ' ') {
        dispatch(setSearchKeyword(''));
        setKeyword('');
      }
    }
    if (
      location?.pathname.includes('search') &&
      location?.pathname !== '/search'
    ) {
      setIsSearchOn(true);
      setIsSearchOptionOn(false);
    }
  }, [location.pathname]);

  useEffect(() => {
    dispatch(setAdChannelKey(platform));
  }, [platform]);

  const onSearchChange = (e) => {
    setKeyword(e.target.value);
  };

  // 送出後避免再輸入，造成search result頁不正確跳轉
  // 切換至gotoSearch
  const onSearchBlur = (e) => {
    e.preventDefault();
    setIsSearchOn(false);
  };

  const encodeKeyword = (keyword) => {
    let value = keyword;
    if (value === '.') {
      value = '. ';
    }
    return encodeURIComponent(value);
  };

  const handleKeyEnter = (e) => {
    if (e.key === 'Enter' && keyword !== '') {
      handleSearch();
    }
  };

  const handleSearch = (e) => {
    // 避免被 gotoSearch 阻擋
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    if (keyword !== '') {
      setIsSearchOn(true);
      const path =
        adChannelKey === 'drug'
          ? `/search/${encodeKeyword(keyword)}`
          : `/search-result?keyword=${encodeKeyword(
              keyword
            )}&platform=${adChannelKey}`;
      navigate(path);
    }
  };

  const gotoSearch = () => {
    if (searchKeyword) {
      if (searchKeyword === ' ') {
        dispatch(setSearchKeyword(''));
        setKeyword('');
      }
      setIsSearchOn(false);
      navigate(`/search?platform=${adChannelKey}`);
    }
  };

  const handleChangeChannel = ({ target }) => {
    const selectedValue = target.value;
    dispatch(setAdChannelKey(selectedValue));
    navigate(`/search?platform=${selectedValue}`, { replace: true });
  };

  return (
    <SearchContainer onClick={gotoSearch}>
      {!isSearchOptionOn ? (
        <StyledTextWrapper>
          {adChannelKey === 'drug' ? '日貨' : '二手'}
        </StyledTextWrapper>
      ) : (
        <StyledSelectorWrapper>
          <select
            name="channel"
            id="channel"
            onChange={handleChangeChannel}
            value={adChannelKey}
          >
            <option key="rakuma" value="rakuma">
              二手
            </option>
            <option key="drug" value="drug">
              日貨
            </option>
          </select>
        </StyledSelectorWrapper>
      )}
      <StyledWrapper>
        {fakeInputVisible ? (
          <StyledInput value="" placeholder="請輸入關鍵字" disabled={true} />
        ) : (
          <StyledInput
            value={keyword}
            placeholder="請輸入商品關鍵字"
            onChange={onSearchChange}
            onBlur={onSearchBlur}
            onKeyPress={handleKeyEnter}
            disabled={isSearchOn}
          />
        )}
      </StyledWrapper>

      {isSearchOptionOn && (
        <StyledButtonWrap>
          <StyledButton
            aria-label="search-button"
            className="searchButton"
            onClick={handleSearch}
            text="搜尋"
          />
        </StyledButtonWrap>
      )}
    </SearchContainer>
  );
};

export default SearchBar;
