import Cookies from 'js-cookie';

export const constants = {
  // FIXME: 寫入 cookie 因 constants 先執行而抓不到，須改成 custom hook 試試看
  token: Cookies.get('ez1') ?? '',
  uid: Cookies.get('uid') ?? '',

  // 不同 API HOST 設定檔
  apiHost: {
    s3: `${process.env.REACT_APP_HOST_S3}`,
    bbn: `${process.env.REACT_APP_HOST_BBN}/api`,
    bbnMarket: `${process.env.REACT_APP_HOST_BBN_MARKET}/185/api`,
    drug: `${process.env.REACT_APP_HOST_DRUG}/api/v3`,
    amazon: `${process.env.REACT_APP_HOST_AMAZON}/api/v1`,
    member: `${process.env.REACT_APP_HOST_MEMBER}/api/v1`,
    cart: `${process.env.REACT_APP_HOST_CART}/api/v1`,
    order: `${process.env.REACT_APP_HOST_ORDER}/api/v1`,
    fmmart: `${process.env.REACT_APP_HOST_FMMART}/api/v1`,
    rakuma: `${process.env.REACT_APP_HOST_RAKUMA}/api/v1`,
  },
  // 頻道 key 由前端寫死 (by 後端)
  channels: {
    main: {
      key: 'main',
      siteName: '日本代購',
    },
    drug: {
      key: 'drug',
      siteName: '好物商城',
    },
    bbc: {
      key: 'bbc',
      siteName: '隨選即買',
    },
    amazon: {
      key: 'amazon',
      siteName: '美國代購',
    },
    rakuten: {
      key: 'rakuten',
      siteName: '日本樂天',
    },
    mercari: {
      key: 'mercari',
      siteName: 'mercari',
    },
    rakuma: {
      key: 'rakuma',
      siteName: 'Rakuma',
    },
  },
  pageTitle: '比比昂日貨',
  // 購物車勾選品項上限
  selectedLimitItem: 15,
  // 購物車勾選重量上限
  selectedLimitWeight: 30,
  // 配送時間
  deliveryTime: {
    0: '不指定',
    1: '上午～下午 13:00 前',
    2: '下午 13:00 後',
  },
  defaultCategoryImage:
    'https://s3.ap-northeast-1.amazonaws.com/ac.ezimport.co.jp/ad/bbctwstore/images/default-mobileSmallCategory.webp',

  // TODO: 之後要再調整並統一業務邏輯(國內配送 type)
  orderDeliveryInfo: {
    0: {
      name: '新竹物流',
      url: 'https://www.hct.com.tw/Search/SearchGoods_n.aspx',
    },
    1: {
      name: '黑貓宅配',
      url: 'https://www.t-cat.com.tw/',
    },
    2: {
      name: '全家取貨',
      url: 'https://fmec.famiport.com.tw/FP_Entrance/QueryBox',
    },
  },

  // API 回傳動作 config
  apiActions: {
    create: '新增',
    update: '編輯',
    delete: '刪除',
  },

  // 無限滾動設定
  infiniteScrollConfig: {
    KEY_CURRENT_PAGE: 'currentPage',
    KEY_PAGE_DATA: 'pageData',
    EXPIRY: 3 * 60 * 1000, // 三分鐘過期
    range: {
      drug: 20,
      rakuma: 12,
    },
  },

  // 刷卡頁連結
  paymentUrl: (orderId) =>
    `https://${
      process.env.REACT_APP_ENV === 'stage'
        ? `dev${process.env.REACT_APP_DOMAIN}`
        : process.env.REACT_APP_HOST_BBN
    }/payment_fm.php?order=${orderId}`,

  // 刷卡頁連結
  familyInfoUrl: (reqData) =>
    `https://${
      process.env.REACT_APP_ENV === 'stage'
        ? `dev${process.env.REACT_APP_DOMAIN}`
        : process.env.REACT_APP_HOST_BBN
    }/api/member/FamilyMartMember.php?${reqData}`,

  // 服務說明網址
  policyUrl: `https://${process.env.REACT_APP_HOST_BBN}/familymart/politics.php`,

  // 折價券使用說明網址
  couponUrl: `https://${process.env.REACT_APP_HOST_BBN}/familymart/politics.php?id=6`,
  qaReplyMessage: '我們已收到您的問題，客服將盡快回覆，還請稍加等候。',
  // 不顯示 radio button 的正規判斷
  enableDefaultLayout: /^(\/member)$/,
  defaultImage: '/images/default_product_img.jpg',
};

export const cartProductStatus = {
  valid: 1,
  notYetOnSale: 2,
  offShelf: 0,
  overdue: -1,
  systemError: -100,
};

export const shippingTag = {
  normal: 0,
  fast: 1,
  slow: 2,
};

// shippingTag 的 map
export const shippingTagText = {
  [shippingTag.normal]: 'normal',
  [shippingTag.fast]: 'fast',
  [shippingTag.slow]: 'slow',
};

// 建議使用的 localStorage 過期時間
export const localStorageDefaultExpireTime = {
  ms: 180000,
  seconds: 180,
  minutes: 3,
  hours: 0.05,
};
