import { calculate } from './calculate';
import { dayjs } from './dayjs';
import { shippingTag } from './constants';
/**
 * leadTime 規則 (以商城為例): key 後兩碼固定為後面天數 (00為以上)，前一或兩碼為前面天數
 *   - 102: 約1-2天工作日
 *   - 204: 約2-4天工作日
 *   - 305: 約3-5天工作日
 *   - 507: 約5-7天工作日
 *   - 711: 約7-11天工作日
 *   - 1114: 約11-14天工作日
 *   - 1500: 約15天以上工作日
 */

/**
 * 備貨期格式: 僅處理代碼判斷，業務邏輯在元件裡處理
 * @param {number} leadTime 代碼
 * @returns {object} { min: 1, max: 3 }
 * @example
 * formatLeadTime(102)
 * formatLeadTime(1114)
 * formatLeadTime(1500)
 */
export const formatLeadTime = (leadTime) => {
  const regex = `${leadTime}`.match(/^(\d+?)(\d{2})$/);

  // 會有極端情境 Ex. 背景向各頻道取資料但尚未更新時，後端會回傳 0
  if (!regex) {
    console.error('無效的輸入');
    return null;
  }

  const [_, min, max] = regex;

  return {
    min: +min,
    max: +max,
  };
};

// TODO-2: 未來會由後端提供規則表，這段就不需要了
// TODO-1: supplierKey 應改為 specialTagId 判斷
// 自採品與非自採品加成(供應商)
export const leadTimeWithSupplier = (leadTime, supplier) => {
  let obj = {
    ...formatLeadTime(leadTime),
  };

  // 自採品
  if (
    supplier === 'bibian' ||
    supplier === '48hour' ||
    supplier === 'fengyun' ||
    supplier === 'goods'
  ) {
    return (obj = {
      min: calculate(obj.min + 4),
      max: calculate(obj.max + 6),
    });
  }

  // 非自採品
  return (obj = {
    min: calculate(obj.min + 5),
    max: calculate(obj.max + 8),
  });
};

/**
 * 多筆品項備貨期推算
 * @param {array} data - 資料須包含 leadTime 與 supplierKey 兩個欄位
 * @param {string} type - supplier(已加成) / pure(未加成)，預設為 supplier
 * @returns {object} { min: 1, max: 3 }
 * 最小(min): 若為 type=supplier，取所有最小中裡的最大值，因多個品項中若遇到較晚出貨的供應商，要以最晚的時間算；反之取最小值中的最小值
 * 最大(max): 取最大值中的最大值
 * @example
 * shipmentTimeByMultiple(data. 'pure') > 未加成
 * shipmentTimeByMultiple(data) > 已加成
 */
export const shipmentTimeByMultiple = (data, type = 'supplier') =>
  data.reduce(
    (acc, { leadTime, supplierKey }) => {
      let min = 0;
      let max = 0;

      if (type === 'pure') {
        min = formatLeadTime(leadTime).min;
        max = formatLeadTime(leadTime).max;
      } else {
        // TODO: supplierKey 應改為 specialTagId 判斷
        min = leadTimeWithSupplier(leadTime, supplierKey).min;
        max = leadTimeWithSupplier(leadTime, supplierKey).max;
      }

      acc.min =
        type === 'pure' ? Math.min(acc.min, min) : Math.max(acc.min, min);
      acc.max = Math.max(acc.max, max);

      return acc;
    },
    {
      min: type === 'pure' ? data[0].leadTime : 0,
      max: 0,
    }
  );

/**
 * 單日(畫面顯示用)
 * @param {number} day - 日
 * @returns {string} YYYY/MM/DD (dd)
 * formatDeliveryDays(2) -> 2024/04/21 (日)
 */
export const formatDeliveryDays = (days, startDate) => {
  const today = startDate ? dayjs(startDate) : dayjs();
  const outputDay = today.add(days, 'day'); // 隔天開始算起，但 .add() 是返回 N 天後的日期
  return outputDay.format('YYYY/MM/DD (dd)');
};

/**
 * 多筆品項配送方式推算
 * @param {array} data - 資料須包含 specialTagId 欄位
 * @returns {string}
 *  - slow(2)
 *  - fast(1)
 *  - normal(0)
 * @description 配送方式(specialTagId): 複合式以最糟的條件為準(烏龜、火箭、一般)
 *  - 烏龜 + 火箭 >> 烏龜
 *  - 烏龜 + 一般 >> 烏龜
 *  - 火箭 + 一般 >> 一般
 *  - 烏龜 + 火箭 + 一般 >> 烏龜
 */
export const shipmentMethodByMultiple = (data) => {
  const hasSlow = data.some((item) => item.shippingTag === shippingTag.slow);
  const hasNormal = data.some(
    (item) => item.shippingTag === shippingTag.normal
  );

  if (hasSlow) {
    return 'slow';
  }

  if (hasNormal) {
    return 'normal';
  }

  return 'fast';
};
